.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.registerHeader {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clearpath-dark-gray);
}
