.addButton {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  color: var(--clearpath-dark-gray);
}

.addButtonContainer {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.card {
  max-height: 100vh;
  flex: 1;
  order: 3;
  overflow-y: auto;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton {
  border: none;
  background-color: transparent !important;
}

.departmentList {
  overflow-y: auto;
  max-height: 40vh;
  padding: 10px;
}

@media screen and (min-width: 576px) {
  .departmentList {
    max-height: unset;
  }
}

.departmentInput {
  padding: 7px 0;
}

.departmentSection {
  display: flex;
  flex-direction: column;
}

.employeeLinkItem {
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.4);
  flex: 1 0 45%;
  min-width: 200px;
  padding: 10px;
  border-radius: 10px;
}

.employeeLinkItem:hover {
  background-color: rgba(254, 193, 16, 0.04);
  border: 1px solid rgba(254, 193, 16, 0.5);
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.formCard {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  background-color: var(--clearpath-dark-gray);
  min-height: 400px;
  padding: 10px;
}

.formCardCenter {
  border-radius: 10px;
  background-color: var(--clearpath-dark-gray);
  width: 100%;
  min-height: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .formCardCenter {
    width: 70%;
  }
}

.formInput {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
}

.textInputContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1;
}

.label {
  font-size: 14px;
  color: var(--muted-text-gray);
  font-weight: 500;
  align-items: center;
  display: flex;
  gap: 5px;
  padding: 7px 0;
}

.loadingText {
  color: var(--muted-text-gray);
  font-size: 14px;
}

.input {
  all: unset;
  background-color: var(--clearpath-dark-gray) !important;
  border-radius: 10px !important;
  border: 1px solid #4b4b4b !important;
}

.inputError {
  all: unset;
  background-color: var(--clearpath-dark-gray) !important;
  border-radius: 10px !important;
  border: 1px solid #f40202 !important;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
  caret-color: #fff;
}

.listItemSubText {
  font-size: 10px;
  color: var(--muted-text-gray);
}

.listItemTag {
  background-color: #232227;
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
}

.listItemTextContainer {
  font-size: 14px;
  font-weight: 500;
  color: white;
  white-space: nowrap;
}

.loadingText {
  color: var(--muted-text-gray);
  text-align: center;
}

.onboardingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--clearpath-yellow);
  color: var(--clearpath-black);
  border-radius: 10px;
  width: 300px;
  min-height: 200px;
  padding: 10px;
}

.onboardingDescription {
  font-size: 14px;
  color: var(--clearpath-light-gray);
  font-weight: 500;
  align-items: center;
  display: flex;
}

.onboardingTitle {
  margin-block-end: 0%;
  flex: 1;
}

.registrationWindowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .registrationWindowContainer {
    width: 70%;
  }
}

@media screen and (min-width: 768px) {
  .register-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}

.roleGroup {
  font-size: 10px;
  color: var(--clearpath-yellow);
  align-items: center;
  display: flex;
}

.roleName {
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.urlText {
  position: absolute;
  left: 10px;
  bottom: 12px;
  color: var(--muted-text-gray);
}
