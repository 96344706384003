*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.accountActionButton {
  background-color: transparent;
  color: #cd3c44;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}

.accountActionButtonContainer {
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: flex-end;
  align-items: flex-start;
}

.addItemButton {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  color: var(--clearpath-dark-gray);
}

.ag-theme-clearpath {
  --ag-alpine-active-color: #333;
  --ag-background-color: var(--clearpath-dark-gray);
  --ag-header-background-color: var(--clearpath-dark-gray-90);
  --ag-odd-row-background-color: var(--clearpath-dark-gray-90);
  --ag-borders: none;
  --ag-row-height: 55px;
  height: calc(100vh - 90px) !important;
  width: 100%;
}

.ag-theme-clearpath .ag-row-hover {
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .ag-theme-clearpath {
    height: calc(100vh - 145px) !important;
  }
}

.ag-theme-clearpath .ag-popup {
  height: 0 !important;
}

.ag-root-wrapper {
  border-radius: 10px;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.arrowLeft {
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 3;
  cursor: pointer;
}

.arrowRight {
  position: absolute;
  right: 0px;
  top: 50%;
  z-index: 3;
  cursor: pointer;
}

.assessmentDetailPreviewCategory {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-shadow: 0 0 4px #000000;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .assessmentDetailPreviewCategory {
    font-size: 14px;
  }
}

.assessmentDetailPreviewInfo {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  padding: 0 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2;
  gap: 10px;
}

@media screen and (max-width: 992px) {
  .assessmentDetailPreviewInfo {
    bottom: 20px;
    padding: 0 10px;
  }
}

@media screen and (max-width: 576px) {
  .assessmentDetailPreviewInfo {
    bottom: 20px;
    padding: 0 10px;
  }
}

.assessmentFeatureItemContainer {
  position: relative;
  height: calc(90vh - 220px);
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .assessmentFeatureItemContainer {
    width: 100%;
  }
}

.attachFileButton {
  background: linear-gradient(180deg, #281e02 0%, #3e3003 0.01%, #342700 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

.badge-round {
  position: absolute;
  top: 10px;
  right: 30px;
  transform: translate(50%, -50%);
  background-color: var(--clearpath-yellow);
  color: var(--clearpath-dark-gray);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  padding: 4px;
}

.betaTag {
  position: absolute;
  top: 20%;
  right: 10%;
  transform: translate(50%, -50%);
  background-color: var(--clearpath-yellow);
  color: var(--clearpath-dark-gray);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  padding: 2px;
}

.blackInput {
  all: unset;
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  text-indent: 10px;
  background-color: var(--clearpath-black);
  border-radius: 10px;
  cursor: text;
}

.blackTextArea {
  all: unset;
  display: flex;
  align-items: center;
  background-color: var(--clearpath-black);
  border-radius: 10px;
  border: 1px solid var(--clearpath-mid-gray);
  resize: vertical;
  min-height: 100px;
  max-height: 400px;
  padding: 10px;
  cursor: text;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-blue {
  background-color: var(--clearpath-blue);
}

.bg-light-gray {
  background-color: var(--clearpath-light-gray) !important;
}

.bg-mid-gray {
  background-color: var(--clearpath-mid-gray) !important;
}

.bg-dark-gray {
  background-color: var(--clearpath-dark-gray);
}

.bg-dark-gray-hover {
  background-color: var(--clearpath-dark-gray);
}

.bg-dark-gray-hover:hover {
  background: linear-gradient(
    90deg,
    rgba(254, 193, 16, 0.19),
    rgba(254, 193, 16, 0)
  );
}

.bg-cp-black-hover {
  background-color: var(--clearpath-black);
}

.bg-cp-black-hover:hover {
  background-color: var(--clearpath-black-50);
}

.bg-cp-black-10 {
  background-color: var(--clearpath-black-10);
}

.bg-cp-black-20 {
  background-color: var(--clearpath-black-20);
}

.bg-cp-black-30 {
  background-color: var(--clearpath-black-30);
}

.bg-cp-black-40 {
  background-color: var(--clearpath-black-40);
}

.bg-cp-black-50 {
  background-color: var(--clearpath-black-50);
}

.bg-cp-black {
  background-color: var(--clearpath-black);
}

.bg-cp-green {
  background-color: var(--clearpath-green) !important;
}

.bg-cp-green-10 {
  background-color: var(--clearpath-green-10);
}

.bg-cp-green-20 {
  background-color: var(--clearpath-green-20);
}

.bg-cp-danger {
  background-color: var(--clearpath-danger);
}

.bg-cp-yellow {
  background-color: var(--clearpath-yellow);
}

.bg-cp-yellow-hover {
  background-color: var(--clearpath-yellow);
}

.bg-cp-yellow-hover:hover {
  background-color: var(--clearpath-yellow-70);
}

.bg-cp-yellow-10 {
  background-color: var(--clearpath-yellow-10);
}

.bg-cp-yellow-50 {
  background-color: var(--clearpath-yellow-50);
}

.bg-cp-yellow-80 {
  background-color: var(--clearpath-yellow-80);
}

.bg-pending-yellow {
  background: repeating-linear-gradient(
    45deg,
    var(--clearpath-yellow-90),
    var(--clearpath-yellow-90) 4px,
    var(--clearpath-yellow) 4px,
    var(--clearpath-yellow) 8px
  );
}

.bg-pending-yellow-10 {
  background: repeating-linear-gradient(
    45deg,
    var(--clearpath-yellow-20),
    var(--clearpath-yellow-20) 4px,
    var(--clearpath-yellow-10) 4px,
    var(--clearpath-yellow-10) 8px
  );
}

.bg-pending-yellow-50 {
  background: repeating-linear-gradient(
    45deg,
    var(--clearpath-yellow-60),
    var(--clearpath-yellow-60) 4px,
    var(--clearpath-yellow-50) 4px,
    var(--clearpath-yellow-50) 8px
  );
}

.bg-pending-danger {
  background: repeating-linear-gradient(
    45deg,
    var(--clearpath-danger-90),
    var(--clearpath-danger-90) 4px,
    var(--clearpath-danger) 4px,
    var(--clearpath-danger) 8px
  );
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "DM Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--clearpath-white);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.border-bottom-none {
  border-bottom: none;
}

.border-bottom-light-gray {
  border-bottom: 1px solid var(--clearpath-light-gray);
}

.border-blue {
  border: 1px solid var(--clearpath-blue);
}

.border-collapse {
  border-collapse: collapse;
}

.border-light-gray {
  border: 1px solid var(--clearpath-light-gray);
}

.border-green {
  border: 1px solid var(--clearpath-green);
}

.border-danger {
  border: 1px solid var(--clearpath-danger);
}

.border-right-light-gray {
  border-right: 1px solid var(--clearpath-light-gray);
}

.border-yellow {
  border: 1px solid var(--clearpath-yellow);
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-half {
  border-radius: 50%;
}

.border-vertical-light-gray {
  border-left: 2px solid var(--clearpath-light-gray);
  width: 1px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-unset {
  bottom: unset;
}

.buttonContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.button-as-div {
  all: unset;
  cursor: pointer;
}

.btnsContainer {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 100%;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .btnsContainer {
    flex-direction: column !important;
  }
}

/* Base Button Class */
.btnBase {
  background: none;
  border: none;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;

  /* Common styles */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  white-space: nowrap;
}

/* Modifier Classes */
.btnGrayAndYellow {
  background-color: transparent;
  color: var(--clearpath-yellow);
  border: 1px solid var(--clearpath-yellow);
}

.btnGrayAndYellow:hover {
  background-color: rgba(241, 183, 12, 0.2);
  cursor: pointer;
}

.btnBlack {
  background-color: var(--clearpath-black);
  color: var(--clearpath-white);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.btnBlack:hover {
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.btnBlue {
  background-color: var(--clearpath-blue);
  color: var(--clearpath-white);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.btnBlue:hover {
  background-color: var(--clearpath-blue-50);
  cursor: pointer;
}

.btnGray {
  background-color: var(--clearpath-dark-gray);
  color: var(--clearpath-white);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.btnGray:hover {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.btnGreen {
  background-color: #28a745;
  color: var(--clearpath-white);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.btnGreen:hover {
  background-color: rgba(40, 167, 69, 0.8);
  cursor: pointer;
}

.btnRed {
  background-color: var(--clearpath-danger);
  color: var(--clearpath-white);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.btnRed:hover {
  background-color: rgba(205, 60, 68, 0.8);
  cursor: pointer;
}

.btnSm {
  padding: 0px 5px !important;
  font-size: 12px !important;
}

.btnLg {
  padding: 10px 20px !important;
  min-width: 100px !important;
  gap: 5px !important;
}

.btnSmText {
  font-size: 10px;
  padding: 4px;
}

.btnTransparent {
  background-color: transparent;
  color: var(--clearpath-white);
  padding: 0;
}

.btnTransparent:hover {
  cursor: pointer;
}

.btnWhiteOutline {
  background-color: transparent;
  color: var(--clearpath-white);
  border: 1px solid var(--clearpath-white);
}

.btnWhiteOutline:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.btnYellow {
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  color: var(--clearpath-black);
  border: 1px solid var(--clearpath-yellow);
}

.btnYellow:hover {
  background: linear-gradient(180deg, #d4a20d 0%, #d09f0c 0.01%, #936e00 100%);
  border: 1px solid #936e00;
  color: var(--clearpath-black);
  cursor: pointer;
}

.btnYellow:disabled {
  background: linear-gradient(180deg, #f6d063 0%, #ffe088 0.01%, #c9af64 100%);
  color: #665f83;
  border: 1px solid #ebce76;
  cursor: not-allowed;
}

.card {
  background-color: var(--clearpath-dark-gray);
  border-radius: 10px;
  box-sizing: border-box;
}

.cardHeader {
  display: flex;
  height: 60px;
  width: 100%;
  gap: 10px;
  align-items: center;
  padding: 0 15px;
  background: linear-gradient(
    to right,
    var(--clearpath-dark-gray),
    var(--clearpath-yellow),
    var(--clearpath-dark-gray)
  );
  background-size: 100% 1px;
  background-position: bottom 0 left 0, bottom 5px left 0;
  background-repeat: no-repeat;
}

.cardHeaderSmall {
  display: flex;
  height: unset;
  width: 100%;
  gap: 10px;
  align-items: center;
  padding: 10px;
  background: unset;
}

.cardHeaderAction {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.cardHeaderTitle {
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: var(--clearpath-white);
  white-space: nowrap;
}

.cardImage {
  height: 200px;
  width: auto;
  object-fit: cover;
  overflow: hidden;
}

.cardSectionHeader {
  display: flex;
  padding: 3px 0;
  gap: 3px;
  min-width: 0;
  align-items: center;
}

.cardTabBtn {
  all: unset;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--clearpath-white);
  white-space: nowrap;
  cursor: pointer;
  padding: 15px;
  gap: 10px;
  border-bottom: 2px solid rgba(41, 39, 51, 1);
  overflow-x: auto;
}

.cardTabButtonActive {
  border-bottom: 2px solid var(--clearpath-yellow);
}

.cardTabCounter {
  font-size: 22px;
  font-weight: bold;
  color: var(--clearpath-yellow);
}

.checkbox {
  position: absolute;
  left: 10px;
  height: 18px;
  width: 18px;
  border: 3px solid var(--clearpath-dark-gray);
  outline: 1px solid rgba(241, 183, 12, 0.5);
  border-radius: 50%;
}

.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.cardFilterSection {
  display: flex;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.centeredRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.chartContainer {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px; /* Add some padding for smaller screens */
  box-sizing: border-box;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .chartContainer {
    max-width: 100%; /* Full width on mobile */
    padding: 0 10px; /* Adjust padding as needed */
  }
}

.clearpathLogo {
  width: 200px;
}

.closeButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  aspect-ratio: 1;
  padding: 10px !important;
  border: none;
  border-radius: 50% !important;
  background-color: transparent !important;
}

@supports not (aspect-ratio: 1) {
  .closeButton::before {
    float: left;
    padding-top: 100%;
    content: "";
  }

  .closeButton::after {
    display: block;
    content: "";
    clear: both;
  }
}

@media screen and (min-width: 992px) {
  .closeButton {
    display: none !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.contentBody {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  min-height: calc(100vh - 20px);
  gap: 10px;
}

@media screen and (min-width: 1026px) {
  .contentBody {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    min-height: calc(100vh - 90px);
  }
}

.cp-black {
  color: var(--clearpath-black) !important;
}

.cp-yellow {
  color: var(--clearpath-yellow) !important;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer !important;
}

.darkInputRight40 {
  all: unset;
  width: calc(100% - 40px) !important;
  padding-inline-end: 40px !important;
  background-color: #38373f !important;
  background-color: var(--clearpath-black) !important;
  height: 2.5rem;
  text-indent: 10px;
  border-radius: 10px;
  width: 100%;
}

.darkListItemContainer {
  display: flex;
  height: 60px;
  min-height: 60px;
  padding: 10px;
  gap: 5px;
  border-radius: 10px;
  background-color: var(--clearpath-black);
  border: 1px solid transparent;
  position: relative;
}

.darkListItemContainer:hover {
  background-color: rgba(254, 193, 16, 0.04);
  border: 1px solid rgba(254, 193, 16, 0.5);
}

.darkListItemSelected {
  display: flex;
  position: relative;
  height: 60px;
  min-height: 60px;
  padding: 10px;
  gap: 5px;
  border-radius: 10px;
  background-color: rgba(254, 193, 16, 0.04);
  border: 1px solid rgba(254, 193, 16, 0.5);
}

.date {
  font-size: 14px;
  font-weight: 500;
  color: var(--muted-text-gray);
  white-space: nowrap;
}

.dateContainer {
  flex: 0 0 auto;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.descriptionText {
  color: var(--muted-text-gray);
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.detail-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background-color: var(--clearpath-dark-gray);
  border-radius: 10px;
  min-height: 40vh;
}

@media (min-width: 768px) {
  .detail-card {
    min-height: calc(100vh - 80px);
  }
}

.detail-ca .detail-card-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

@keyframes fadeInScaleUp {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeOutScaleDown {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: var(--clearpath-dark-gray);
  padding: 10px;
  min-width: 300px;
  border: 1px solid var(--clearpath-yellow);
  border-radius: 10px;
  animation: fadeInScaleUp 0.1s ease-out forwards;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.fadeIn {
  animation: fadeInScaleUp 0.1s ease-out forwards;
}

@media screen and (max-width: 576px) {
  .dialog {
    width: 100%;
    transform: none;
  }
}

.dialog-exit {
  animation: fadeOutScaleDown 0.1s ease-out forwards;
}

.dialog-content {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
  max-height: 80vh;
}

.dialog-close-button {
  position: absolute;
  top: 17px;
  right: 10px;
  cursor: pointer;
}

.dialog-description {
  color: var(--muted-text-gray);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.dialog-footer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-title {
  margin: 0;
  color: var(--clearpath-yellow);
}

.dialog-form {
  min-width: 400px;
}

@media screen and (max-width: 576px) {
  .dialog-form {
    width: 100%;
  }
}

.discussionSendButton {
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

.discussionCheckbox {
  position: absolute;
  right: 10px;
  height: 18px;
  width: 18px;
  border: 3px solid var(--clearpath-dark-gray);
  outline: 1px solid rgba(241, 183, 12, 0.5);
  border-radius: 50%;
}

.discussionCheckboxLabel {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 1px solid var(--clearpath-mid-gray);
}

.discussionCheckboxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.discussionCheckboxLabel:hover .discussionCheckbox {
  transition: ease-out 0.4s;
  background-color: var(--clearpath-yellow);
  padding: 2px;
  border-radius: 50%;
}

.discussionCheckboxLabel input:checked ~ .discussionCheckbox {
  background-color: var(--clearpath-yellow);
}

.discussionCheckbox:after {
  content: "";
  position: absolute;
  display: none;
}

.discussionCheckboxChoice {
  border-left: 2px solid transparent;
  padding: 0 5px;
}

.discussionCheckboxChoice:hover {
  background: linear-gradient(
    90deg,
    rgba(254, 193, 16, 0.19),
    rgba(254, 193, 16, 0)
  );
  border-left: 2px solid var(--clearpath-yellow);
}

.discussionCheckboxTextContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 0 10px;
  gap: 5px;
}

.discussionCheckboxTitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--clearpath-white);
}

.discussionCheckboxSubtitle {
  font-size: 14px;
  color: var(--muted-text-gray);
}

.discussionEmployeeFilterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.discussionFilterSortText {
  font-size: 14px;
  color: var(--muted-text-gray);
}

.discussionHeader {
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-bottom: 1px solid var(--clearpath-mid-gray);
  height: 70px;
}

.discussionListContainer {
  display: flex;
  flex: 1;
  max-height: 50vh;
  overflow-y: auto;
}
.discussionListUserCount {
  position: absolute;
  height: 25px;
  width: 25px;
  left: 10px;
  bottom: 0px;
  border-radius: 50%;
  background-color: var(--clearpath-yellow);
  border: 1px solid var(--clearpath-white);
  color: var(--clearpath-black);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  z-index: 300;
}

.discussionMessage {
  font-size: 14px;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.discussionMessageReponseContainer {
  padding: 10px;
  background-color: var(--clearpath-mid-gray);
  border-radius: 10px;
  border-bottom-left-radius: 0;
  max-width: 300px;
}

.discussionMessageSenderContainer {
  padding: 10px;
  background-color: var(--clearpath-yellow);
  border-radius: 10px;
  border-bottom-right-radius: 0;
  max-width: 300px;
}

.discussionMessageTimeResponseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 0;
}

.discussionMessageTimeSenderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 0;
}

.discussionOptionsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--clearpath-mid-gray);
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

@supports not (aspect-ratio: 1) {
  .discussionOptionsButton::before {
    float: left;
    padding-top: 100%;
    content: "";
  }

  .discussionOptionsButton::after {
    display: block;
    content: "";
    clear: both;
  }
}

.discussionOptionsContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.discussionSearchContainer {
  display: unset;
  flex: 1;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 576px) {
  .discussionSearchContainer {
    flex-direction: row;
    display: flex;
  }
}

.discussionThreeUsersImageOne {
  height: 50%;
}

.discussionThreeUsersContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 45px;
}

.discussionTwoUsersContainer {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  position: relative;
  width: 45px;
}

.discussionUsersImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.discussionUsersImageContainer img {
  height: 100%;
  border-radius: 50%;
}

.discussionUsersInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3px;
  justify-content: center;
}

.discussionUsersName {
  font-size: 16px;
  font-weight: 500;
  color: var(--clearpath-white);
}

.discussionUsersRole {
  font-size: 14px;
  font-weight: 500;
  color: var(--muted-text-gray);
  white-space: nowrap;
}

.discussionUsersInfoContainer {
  display: flex;
  flex: 4;
  gap: 10px;
}

.discussionVideoPlayer {
  border-radius: 12px;
}

.discussionWrapper {
  display: flex;
  width: 50px;
}

.descriptionText {
  color: var(--muted-text-gray);
  padding: 0 1em;
  font-size: 14px;
}

.dropdownBtn {
  cursor: pointer;
  background-color: transparent;
  color: var(--clearpath-white);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
  white-space: nowrap;
  width: 100%;
  border: none !important;
  gap: 5px;
}

.dropdownBtn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdownContainer {
  flex-direction: column;
  position: absolute;
  right: 15px;
  top: 40px;
  background-color: var(--clearpath-black);
  border: 1px solid rgba(241, 183, 12, 0.26);
  z-index: 999;
  font-size: 14px;
  min-width: 50px;
  border-radius: 10px 0px 10px 10px;
}

.dropdownMenuItem {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.dropdownTitle {
  width: 100%;
  border-bottom: 1px solid rgba(241, 183, 12, 0.26);
  padding: 10px;
}

.employeeContainer {
  background: linear-gradient(
    to right,
    var(--clearpath-dark-gray),
    var(--clearpath-yellow),
    var(--clearpath-dark-gray)
  );
  background-size: 100% 1px;
  background-position: bottom 0 left 0, bottom 5px left 0;
  background-repeat: no-repeat;
}

.employeeDropdownContainer {
  flex-direction: column;
  position: absolute;
  left: 30px;
  top: 40px;
  background-color: var(--clearpath-black);
  border: 1px solid rgba(241, 183, 12, 0.26);
  padding: 20px 0px;
  z-index: 999;
  font-size: 14px;
  color: var(--muted-text-gray);
  min-width: 50px;
  border-radius: 0px 10px 10px 10px;
}

.employeeInfoContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  align-items: center;
}

.fileLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.openInNewTabButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.typing {
  margin: 5px 0;
  padding: 10px 15px;
  background-color: var(--clearpath-mid-gray);
  border-radius: 10px;
  border-bottom-left-radius: 0;
  width: 5em;
  height: 2em;
  position: relative;
}

.typingDot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typingDot:nth-child(1) {
  animation-delay: 0s;
}

.typingDot:nth-child(2) {
  animation-delay: 0.2s;
}

.typingDot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.uploadFile {
  width: 100%;
  height: 100px;
  padding: 1em;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 1em;
}

.document-approval-annotation {
  position: absolute;
  cursor: pointer;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 1;
}

.document-approval-annotation-text {
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding: 5px;
}

.document-description {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-annotation-checkboxes {
  border: 1px solid #000;
  background-color: var(--clearpath-yellow);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  color: black;
  font-size: 10px;
}

.document-sign-annotation-checkboxes {
  border: 2px solid red;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  color: black;
  font-size: 10px;
}

.document-annotation-dropdown {
  border: 1px solid #000;
  background-color: var(--clearpath-yellow);
  position: absolute;
}

.document-sign-annotation-dropdown {
  border: 2px solid red;
  background-color: white;
  position: absolute;
}

.document-sign-annotation {
  position: absolute;
  border: 2px solid red;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 8px;
  color: black;
  top: 0px;
  left: 0px;
  width: 150px;
  height: 40px;
  cursor: pointer;
}

.drag-and-drop-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: 100%;
  min-height: 200px;
  padding: 0 20px;
  border-radius: 15px;
  border: 1px dashed var(--clearpath-yellow);
  background-color: var(--clearpath-yellow-10);
}

.drag-and-drop-media-container img,
.drag-and-drop-media-container video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.drag-and-drop-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.drag-and-drop-media-container img,
.drag-and-drop-media-container video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.drag-and-drop-remove-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50%;
  background-color: var(--clearpath-dark-gray);
}

.dropDownContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
}

.dropDownInputContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 0.5rem;
  min-width: 80px;
}

.dropDownMenuContainer {
  flex-direction: column;
  position: absolute;
  right: 15px;
  background-color: var(--clearpath-black);
  border: 1px solid var(--clearpath-mid-gray);
  z-index: 999;
  color: var(--muted-text-gray);
  border-radius: 10px;
  gap: 10px;
  padding: 10px;
  transition: transform 0.3s ease-in-out;
  top: 100%;
  min-width: 250px;
}

.dropDownSectionContainer {
  width: 100%;
}

.dropDownContentContainer {
  display: flex;
  flex-direction: column;
}

.dropdownTitle {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: var(--clearpath-white);
}

.dropDownTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}

.dropDownV2Button {
  all: unset;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  white-space: nowrap;
}

.dropDownV2Button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.employeeImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid var(--clearpath-light-gray);
  object-fit: cover;
}

.employeeImageContainer {
  width: 140px;
  height: 140px;
  position: relative;
  z-index: 1;
}

.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}

.featureItemContainer {
  position: relative;
  height: calc(100vh - 300px);
  overflow: hidden;
}

@media screen and (max-width: 1025px) {
  .featureItemContainer {
    width: 100%;
    height: 30vh;
  }
}

.featureItemImageContainer {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.featureItemOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90%;
  background: linear-gradient(to top, var(--clearpath-black), transparent);
  pointer-events: none;
}

.flexTitleContainer {
  color: var(--clearpath-white);
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-1-desktop {
  flex: 1;
}

@media screen and (max-width: 576px) {
  .flex-1-desktop {
    flex: unset;
  }
}

.flex-2 {
  flex: 2;
}

.flex {
  display: flex;
  flex-direction: column;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-row-mobile-column {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 576px) {
  .flex-row-mobile-column {
    flex-direction: column;
  }
}

.rotate-90 {
  @media screen and (max-width: 576px) {
    transform: rotate(90deg);
  }
}

.flexTitle {
  display: flex;
  flex: 1;
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.form-fields {
  display: flex;
  min-width: 300px;
  max-height: 80vh;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin: 10px;
  overflow-y: auto;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.grid {
  display: grid;
}

.grid-2x1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media (max-width: 996px) {
  .grid-2x1 {
    grid-template-columns: 1fr;
  }
}

.grid-2x1-rows {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
}

@media screen and (max-width: 996px) {
  .grid-2x1-rows {
    grid-template-rows: 1fr;
  }
}

.grid-auto-rows-minmax {
  grid-auto-rows: minmax(auto, auto);
}

.grid-cols-100px-7fr {
  grid-template-columns: minmax(100px, 1fr) repeat(7, minmax(100px, 1fr));
}

.grid-column-span-full {
  grid-column: 1 / -1;
}

.grid-item {
  padding: 10px;
  min-height: 250px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  }
}

.grid-two-column {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 1fr));
  gap: 10px;
}

.grid-item-square {
  width: 100%;
}

/* Desktop and Tablet (two-column grid) */
@media (min-width: 996px) {
  .grid-two-column {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile (one-column grid) */
@media (max-width: 995px) {
  .grid-two-column {
    grid-template-columns: 1fr;
  }
}

.h-auto {
  height: auto;
}

.h-20 {
  height: 20px;
}

.h-40 {
  height: 40px;
}

.h-50 {
  height: 50px;
}

.h-60 {
  height: 60px;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100px;
}

.h-150 {
  height: 150px;
}

.h-300 {
  height: 300px;
}

.h-40vh {
  height: 40vh;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-unset {
  height: unset;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-bottom: 1px solid var(--clearpath-mid-gray);
  height: 70px;
  gap: 10px;
}

.headerDisplayContainer {
  height: 100%;
  aspect-ratio: 1;
}

.headerInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-width: 0;
  /* overflow-x: hidden; */
}

.headerActionsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.hidden {
  display: none;
}

.hideMobile {
  display: none !important;
}

@media screen and (min-width: 576px) {
  .hideMobile {
    display: unset !important;
  }
}

@media screen and (min-width: 992px) {
  .hideOnDesktop {
    display: none !important;
  }
}

.horizontalListTitle {
  font-size: 20px;
  font-weight: bold;
  color: var(--clearpath-yellow);
  padding-bottom: 20px;
}

.horizontalScrollContainer {
  gap: 10px;
  display: flex;
  overflow-x: auto;
  position: relative;
}

.horizontalScrollContainer::-webkit-scrollbar {
  display: none;
}

.horizontalScrollContainer > * {
  flex-shrink: 0;
}

.horizontalScrollList {
  position: relative;
}

.hover-bg-white:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.hover-bg-white-20:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.imageUploadIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid var(--clearpath-light-gray);
}

.imageUploadIconContainer:hover {
  border: 1px solid var(--clearpath-light-gray);
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.imageUploadInput {
  cursor: pointer;
  width: 100%;
  height: 100%;
  bottom: 60px;
  z-index: 999;
  display: none;
}

.vh-100-w-header {
  height: calc(100vh - 150px);
}

.vh-min-100 {
  min-height: calc(100vh - 90px);
}

html {
  box-sizing: border-box;
  background-color: var(--clearpath-black);
}

.icon-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  color: var(--clearpath-dark-gray);
}

.icon-button:hover {
  background: linear-gradient(180deg, #d4a20d 0%, #d09f0c 0.01%, #936e00 100%);
}

.imageContainer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--clearpath-yellow);
  width: 20px;
  transition: left 0.3s ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="radio"] {
  accent-color: var(--clearpath-black) !important;
  margin: 3px;
}

input::placeholder {
  font-size: 16px;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: var(--clearpath-yellow);
  margin-right: 10px;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.6;
  pointer-events: all;
}

.input40 {
  all: unset;
  width: calc(100% - 40px) !important;
  padding-inline-end: 40px !important;
  background-color: #38373f !important;
  background-color: var(--clearpath-mid-gray) !important;
  height: 2.5rem;
  text-indent: 10px;
  border-radius: 10px;
  width: 100%;
}

.inputDarkError {
  all: unset;
  height: 2.5rem;
  display: flex;
  align-items: center;
  text-indent: 10px;
  background-color: var(--clearpath-dark-gray);
  border-radius: 10px;
  border: 1px solid #f40202;
  cursor: text;
}

.inputDark {
  height: 2.5rem;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: var(--clearpath-dark-gray);
  border-radius: 10px;
  border: 1px solid var(--clearpath-mid-gray);
  color: inherit; /* Ensure text color is applied */
  cursor: text;
  appearance: none; /* Remove default appearance to customize fully */
  outline: none; /* Removes the blue focus outline */
}

.inputDark:focus {
  outline: none; /* Ensures no outline on focus */
  border-color: var(
    --clearpath-light-gray
  ); /* Optionally change border color on focus */
}

.inputBlack {
  height: 2.5rem;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: var(--clearpath-black);
  border-radius: 10px;
  border: 1px solid var(--clearpath-mid-gray);
  color: inherit; /* Ensure text color is applied */
  cursor: text;
  appearance: none; /* Remove default appearance to customize fully */
  outline: none; /* Removes the blue focus outline */
}

.inputBlackError {
  all: unset;
  height: 2.5rem;
  display: flex;
  align-items: center;
  text-indent: 10px;
  background-color: var(--clearpath-black-50);
  border-radius: 10px;
  border: 1px solid #f40202;
  cursor: text;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  color-scheme: dark;
}

.inputDark:disabled {
  color: var(--clearpath-light-gray);
}

.inputDescription {
  margin-top: 5px;
  font-size: 12px;
  color: var(--muted-text-gray);
}

.inputError {
  padding: 5px;
  color: red;
  font-size: 14px;
}

.input-select {
  all: unset;
  background-color: var(--clearpath-dark-gray) !important;
  border-radius: 10px !important;
  border: 1px solid var(--clearpath-mid-gray) !important;
  line-height: 2.5 !important;
  text-indent: 10px;
}

.inviteButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 10px;
}

.items-center {
  display: flex;
  align-items: center;
}

.items-start {
  display: flex;
  align-items: flex-start;
}

.items-end {
  display: flex;
  align-items: flex-end;
}

.items-unset {
  align-items: unset;
}

.justify-mobile-items-center-desktop {
  align-items: center;
}

@media screen and (max-width: 996px) {
  .justify-mobile-items-center-desktop {
    align-items: center;
  }
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.listCard {
  background-color: var(--clearpath-dark-gray);
  border-radius: 10px;
}

@media screen and (min-width: 992px) {
  .listCard {
    position: relative;
  }
}

.listCardContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

@media screen and (max-width: 996px) {
  .listCardContainer {
    position: unset;
  }
}

@media screen and (max-width: 576px) {
  .listCardContainer {
    position: unset;
  }
}

.listItem {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    to right,
    var(--clearpath-dark-gray),
    rgba(241, 183, 12, 0.2),
    var(--clearpath-dark-gray)
  );
  background-size: 100% 1px;
  background-position: bottom 0 left 0, bottom 5px left 0;
  background-repeat: no-repeat;
}

.listItemImage {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.listItemLink {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 10px 15px;
  border-left: 2px solid transparent;
}

.listItemLink:hover {
  background: linear-gradient(
    90deg,
    rgba(254, 193, 16, 0.19),
    rgba(254, 193, 16, 0)
  );
  border-left: 2px solid var(--clearpath-yellow);
  cursor: pointer;
}

.label {
  font-size: 12px;
  color: var(--muted-text-gray);
  font-weight: 500;
  align-items: center;
  display: flex;
  gap: 5px;
  padding: 7px 0;
}

.leftNav {
  height: 100%;
}

@media screen and (min-width: 992px) {
  .leftNav {
    width: 200px;
  }
}

.leftNavContainer {
  background-color: var(--clearpath-dark-gray);
  border-radius: 0px 20px 20px 0px;
  width: 200px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
  height: 100vh;
}

/* if backdrop support: very transparent and blurred */
/* @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .leftNavContainer {
    backdrop-filter: blur(20px);
    background-color: unset;
  }
} */

@media screen and (min-width: 576px) {
  .leftNavContainer {
    width: 200px;
  }
}

@media screen and (min-width: 992px) {
  .leftNavContainer {
    backdrop-filter: unset;
    background-color: var(--clearpath-dark-gray);
    display: flex;
    height: unset;
    width: 200px;
    position: unset;
  }
}

.leftNavContainerHidden {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

@media screen and (min-width: 992px) {
  .leftNavContainerHidden {
    transform: unset;
  }
}

.leftNavContainerShown {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.link-yellow {
  color: var(--clearpath-yellow);
}

.link-yellow:hover {
  color: var(--clearpath-yellow-30);
}

.listItemTextContainer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.listItemSubTitle {
  font-size: 10px;
  font-weight: 500;
  color: var(--muted-text-gray);
  white-space: nowrap;
  overflow-x: hidden;
}

.listItemTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--clearpath-white);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.lg\:max-w-xs {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

.lg\:max-w-sm {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
}

.lg\:max-w-md {
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
}

.lg\:max-w-lg {
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
}

.lg\:max-w-xl {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1024px) {
  .lg\:max-w-xs,
  .lg\:max-w-sm,
  .lg\:max-w-md,
  .lg\:max-w-lg,
  .lg\:max-w-xl {
    max-width: 100%;
  }
}

.loadingAnimation {
  width: 90%;
  max-width: 500px;
}

.loadingContainer {
  height: unset !important;
  flex: unset !important;
}

.logoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  padding: 20px 10px;
}

@media screen and (max-width: 776px) {
  .logoContainer {
    padding: 10px;
  }
}

.logoContainer a {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .logoContainer {
    padding: 20px 30px;
    justify-content: center;
  }
}

.logoContainer img {
  width: 70%;
}

@media screen and (min-width: 992px) {
  .logoContainer img {
    width: 100%;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex: 3 1 0;
  min-width: 0;
  min-height: 0;
  gap: 10px;
}

.markdown-content a {
  color: var(--clearpath-yellow);
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  color: var(--clearpath-yellow);
}

.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.max-container {
  width: 100%;
  height: 90%;
}

.max-40vh {
  max-height: 40vh;
}

.m-unset {
  margin: unset;
}

.m-1rem {
  margin: 1rem;
}

.m-10 {
  margin: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-1rem {
  margin-left: 1rem;
}

.ml-2rem {
  margin-left: 2rem;
}

.mr-10 {
  margin-right: 10px;
}

.mt-auto {
  margin-top: auto;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-300-mobile-full {
  max-width: 300px;
}

@media screen and (max-width: 1026px) {
  .max-w-300-mobile-full {
    max-width: 100%;
  }
}

.max-w-350-mobile-full {
  max-width: 350px;
}

@media screen and (max-width: 1026px) {
  .max-w-350-mobile-full {
    max-width: 100%;
  }
}

.mh-100 {
  min-height: 100px;
}

.mh-150 {
  min-height: 150px;
}

.mh-200 {
  min-height: 200px;
}

.mh-300 {
  min-height: 300px;
}

.mh-500 {
  min-height: 500px;
}

.mh-unset {
  min-height: unset;
}

@media screen and (max-width: 576px) {
  .max-vh-50-mobile {
    max-height: 50vh;
  }
}

.max-h-200 {
  max-height: 200px;
}

.max-h-300 {
  max-height: 300px;
}

.max-h-500 {
  max-height: 500px;
}

.min-h-300 {
  min-height: 300px;
}

.min-h-500 {
  min-height: 500px;
}

.min-h-40vh {
  min-height: 40vh;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-100vh-90 {
  min-height: calc(100vh - 90px);
}

.max-h-100vh-90 {
  max-height: calc(100vh - 90px);
}

.min-w-200 {
  min-width: 200px;
}

.min-w-300 {
  min-width: 300px;
}

.mw-100 {
  min-width: 100px;
}

.max-w-100 {
  max-width: 100px;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-500 {
  max-width: 500px;
}

.max-w-700 {
  max-width: 700px;
}

.modal-footer {
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  background-color: var(--clearpath-dark-gray);
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.modalListContainer {
  height: 200px;
  overflow: auto;
  background-color: #000;
  border-radius: 10px;
}

.multiple-button-input {
  all: unset;
  background-color: var(--clearpath-dark-gray) !important;
  border-radius: 10px !important;
  border: 1px solid var(--clearpath-mid-gray) !important;
  height: 100px;
  overflow-y: scroll;
  padding: 10px;
  gap: 10px;
}

.multiple-button-input-dark {
  all: unset;
  background-color: var(--clearpath-black) !important;
  border-radius: 10px !important;
  /* border: 1px solid var(--clearpath-mid-gray) !important; */
  height: 150px;
  overflow-y: scroll;
  padding: 10px;
  gap: 10px;
}

.multiple-button-container {
  padding: 5px;
  display: inline-block;
}

.multiple-button-active {
  all: unset;
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  color: var(--clearpath-black);
  border: 1px solid var(--clearpath-yellow);
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
}

.multiple-button {
  all: unset;
  background-color: var(--clearpath-mid-gray);
  color: var(--clearpath-white);
  border: 1px solid var(--clearpath-mid-gray);
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
}

.multiple-button:hover {
  background-color: var(--clearpath-light-gray);
  color: var(--clearpath-white);
  border: 1px solid var(--clearpath-light-gray);
}

.navLink {
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--clearpath-white);
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-left: 2px solid transparent;
}

@media screen and (min-width: 992px) {
  .navLink {
    padding: 7px 10px 7px 30px;
  }
}

.navLink:hover,
.navLinkSelected {
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--clearpath-white);
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(254, 193, 16, 0.19),
    rgba(254, 193, 16, 0)
  );
  border-left: 2px solid var(--clearpath-yellow);
}

@media screen and (min-width: 992px) {
  .navLink:hover,
  .navLinkSelected {
    padding: 7px 10px 7px 30px;
  }
}

.navLinkText {
  margin-left: 10px;
}

.navLinkListItem {
  position: relative;
  font-size: 16px;
}

@media screen and (min-width: 992px) {
  .navLinkListItem {
    margin: 5px 0;
  }
}

.navLinksList {
  padding: 0;
  list-style-type: none;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .navLinksList {
    margin: 0;
  }
}

.navLinksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navMenuButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  aspect-ratio: 1;
  padding: 10px !important;
  border: none;
  border-radius: 50% !important;
  background-color: var(--clearpath-dark-gray) !important;
}

@supports not (aspect-ratio: 1) {
  .navMenuButton::before {
    float: left;
    padding-top: 100%;
    content: "";
  }

  .navMenuButton::after {
    display: block;
    content: "";
    clear: both;
  }
}

@media screen and (min-width: 992px) {
  .navMenuButton {
    display: none !important;
  }
}

.note-file-container {
  margin: 10px 0;
}
.object-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.onboardingCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  background-color: var(--clearpath-dark-gray);
  min-height: 400px;
  padding: 10px;
}

.opacity-60 {
  opacity: 0.6;
}

.optionsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--clearpath-mid-gray);
  padding: 5px;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

@supports not (aspect-ratio: 1) {
  .optionsButton::before {
    float: left;
    padding-top: 100%;
    content: "";
  }

  .optionsButton::after {
    display: block;
    content: "";
    clear: both;
  }
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.pdfToolBar {
  display: flex;
  height: 40px;
  background-color: var(--clearpath-dark-gray);
}

.pdfToolBarContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
  margin-left: 93px;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .pdfToolBarContainer {
    margin-left: 0;
  }
}

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-50 {
  padding: 50px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.pt-10 {
  padding-top: 10px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.placeholder {
  padding: 4px 0px;
  font-size: 16px;
  font-weight: 500;
  color: var(--clearpath-yellow);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirrorWithResize {
  resize: vertical;
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
  padding: 10px;
  background-color: var(--clearpath-dark-gray);
  border-radius: 10px;
}

.ProseMirror {
  overflow: auto;
  min-height: 200px;
  padding: 10px;
  background-color: var(--clearpath-dark-gray);
  border-radius: 10px;
}

.prevPage {
  position: absolute !important;
  z-index: 1;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #000;
  color: #fff;
  border: 1px solid var(--clearpath-yellow);
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  z-index: 9999;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--clearpath-yellow-40);
  color: rgb(0, 0, 0);
}

react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--clearpath-yellow-60);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--clearpath-yellow-40);
  color: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: black;
  border-bottom: 1px solid var(--clearpath-yellow);
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: var(--clearpath-yellow-40);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--clearpath-white);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: var(--clearpath-yellow-40);
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: var(--clearpath-yellow-40);
}

/* .react-datepicker-wrapper { */
/* z-index: 9998; */
/* } */

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: var(--clearpath-yellow);
}

.relative {
  position: relative;
}

:root {
  --clearpath-blue: #0096ff;
  --clearpath-blue-50: rgba(0, 150, 255, 0.5);
  --clearpath-yellow: #f1b70c;
  --clearpath-yellow-10: rgba(241, 183, 12, 0.1);
  --clearpath-yellow-20: rgba(241, 183, 12, 0.2);
  --clearpath-yellow-30: rgba(241, 183, 12, 0.3);
  --clearpath-yellow-40: rgba(254, 193, 16, 0.4);
  --clearpath-yellow-50: rgba(254, 193, 16, 0.5);
  --clearpath-yellow-60: rgba(254, 193, 16, 0.6);
  --clearpath-yellow-70: rgba(254, 193, 16, 0.7);
  --clearpath-yellow-80: rgba(254, 193, 16, 0.8);
  --clearpath-yellow-90: rgba(254, 193, 16, 0.9);
  --clearpath-green: #28a745;
  --clearpath-green-10: rgba(40, 167, 69, 0.1);
  --clearpath-green-20: rgba(40, 167, 69, 0.2);
  --clearpath-green-30: rgba(40, 167, 69, 0.3);
  --clearpath-green-40: rgba(40, 167, 69, 0.4);
  --clearpath-green-50: rgba(40, 167, 69, 0.5);
  --clearpath-green-60: rgba(40, 167, 69, 0.6);
  --clearpath-green-70: rgba(40, 167, 69, 0.7);
  --clearpath-green-80: rgba(40, 167, 69, 0.8);
  --clearpath-green-90: rgba(40, 167, 69, 0.9);
  --clearpath-danger: #cd3c44;
  --clearpath-danger-10: rgba(205, 60, 68, 0.1);
  --clearpath-danger-20: rgba(205, 60, 68, 0.2);
  --clearpath-danger-30: rgba(205, 60, 68, 0.3);
  --clearpath-danger-40: rgba(205, 60, 68, 0.4);
  --clearpath-danger-50: rgba(205, 60, 68, 0.5);
  --clearpath-danger-60: rgba(205, 60, 68, 0.6);
  --clearpath-danger-70: rgba(205, 60, 68, 0.7);
  --clearpath-danger-80: rgba(205, 60, 68, 0.8);
  --clearpath-danger-90: rgba(205, 60, 68, 0.9);
  --clearpath-white: #ffffff;
  --clearpath-white-10: rgba(255, 255, 255, 0.1);
  --clearpath-light-gray: #4f4f4f;
  --clearpath-mid-gray: #38373f;
  --clearpath-dark-gray-90: rgba(1, 0, 5, 0.3);
  --clearpath-dark-gray: #232227;
  --clearpath-black: #0c0b10;
  --clearpath-black-10: rgba(12, 11, 16, 0.1);
  --clearpath-black-20: rgba(12, 11, 16, 0.2);
  --clearpath-black-30: rgba(12, 11, 16, 0.3);
  --clearpath-black-40: rgba(12, 11, 16, 0.4);
  --clearpath-black-50: rgba(12, 11, 16, 0.5);
  --clearpath-black-60: rgba(12, 11, 16, 0.6);
  --clearpath-black-70: rgba(12, 11, 16, 0.7);
  --clearpath-black-80: rgba(12, 11, 16, 0.8);
  --clearpath-black-90: rgba(12, 11, 16, 0.9);

  --muted-text-gray: #bbbbbb;

  --select-focus: red;
  --select-arrow: #bbbbbb;

  --table-dark: rgb(21, 20, 23);
  --table-dark-row-hover: linear-gradient(
    90deg,
    rgba(21, 20, 23, 0.8),
    rgba(42, 32, 3, 0.8),
    rgba(21, 20, 23, 0.8)
  );

  --table-light: var(--clearpath-dark-gray);
  --table-light-row-hover: linear-gradient(
    90deg,
    rgba(254, 193, 16, 0),
    rgba(106, 81, 8, 0.4),
    rgba(254, 193, 16, 0)
  );

  --r-onboarding-overlay-z: 900;
  --r-onboarding-step-z: 1000;
}

.sigCanvas {
  border: 1px solid var(--clearpath-yellow);
  background-color: var(--clearpath-white);
  width: 100%;
}

@media (min-width: 768px) {
  .sigCanvas {
    width: 370px;
  }
}

.searchContainer {
  position: relative;
  display: flex;
  flex: 1;
}

.snap-start {
  scroll-snap-align: start;
}

.formSearchContainer {
  display: unset;
  flex: 1;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
}

@media screen and (min-width: 576px) {
  .formSearchContainer {
    flex-direction: row;
    display: flex;
  }
}

.rounded-full {
  border-radius: 50%;
}

.mobile-title {
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 576px) {
  .mobile-title {
    font-size: 12px;
  }
}

.schedule-key-row {
  display: flex;
  flex-direction: row;
  height: 40px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@media screen and (max-width: 576px) {
  .schedule-key-row {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: flex-start;
  }
}

.searchIconContainer {
  position: absolute;
  top: 10px;
  left: 12px;
}

.sectionHeaderContainer {
  display: flex;
  flex-direction: column;
  height: 135px;
  flex: 1;
}

@media screen and (min-width: 576px) {
  .sectionHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    max-height: 80px;
  }
}

.sectionHeaderTitleContainer {
  display: flex;
  flex: 4;
  gap: 5px;
  align-items: center;
}

.sectionHeaderTitle {
  display: flex;
  font-weight: 500;
  color: var(--clearpath-white);
}

.sectionHeaderRightContainer {
  display: flex;
  flex: 4;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .sectionHeaderRightContainer {
    width: unset;
  }
}

.select-option {
  font-size: 16px;
  font-weight: 500;
}

.sendButton {
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.step {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.step::before {
  content: "";
  border-left: 2px solid var(--clearpath-yellow-30);
  height: 100%;
  position: absolute;
  left: 16px;
  top: 21px;
}

.step:last-child::before {
  border-left: none;
}

.sticky {
  position: sticky;
}

.subtitleContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  color: var(--muted-text-gray);
}

.tabContainer {
  position: relative;
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch !important;
  white-space: nowrap;
}

.tabContainer::-webkit-scrollbar {
  display: none;
}

.tab {
  font-size: 14px;
  padding: 10px 40px 10px 0px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  flex-shrink: 0;
}

.tab:hover {
  color: var(--muted-text-gray);
}

.tab.active {
  color: var(--clearpath-yellow);
}

.tabContent {
  margin-top: 10px;
}

@media screen and (min-width: 992px) {
  .tabContent {
    max-width: calc(100vw - 220px);
  }
}

.table {
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table > tbody {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table > tbody > tr > td,
.table > thead > tr > th {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--table-dark) !important;
  height: 50px;
  background: linear-gradient(
    to right,
    var(--clearpath-dark-gray),
    rgba(241, 183, 12, 0.1),
    var(--clearpath-dark-gray)
  );
  background-size: 100% 1px;
  background-position: bottom 0 left 0, bottom 5px left 0;
  background-repeat: no-repeat;
  border-left: 2px solid transparent;
  padding: 1em;
  text-align: left;
}

.tableContainer {
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
  border-radius: 5px;
}

.tableCellButtonContainer {
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tableImageContainer {
  width: 40px;
  height: 40px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-end {
  text-align: end;
}

.text-area-input-black {
  all: unset;
  background-color: var(--clearpath-black);
  border-radius: 10px;
  border: 1px solid var(--clearpath-mid-gray);
  color: var(--clearpath-white);
  cursor: text;
  padding: 10px;
  resize: none;
}

.text-area-input-black-error {
  all: unset;
  background-color: var(--clearpath-black-50);
  border-radius: 10px;
  border: 1px solid #f40202;
  color: var(--clearpath-white);
  cursor: text;
  padding: 10px;
  resize: none;
}

.text-primary {
  color: var(--clearpath-yellow);
}

.text-danger {
  color: var(--clearpath-danger);
}

.text-light-gray {
  color: var(--clearpath-light-gray);
}

.text-muted {
  color: var(--muted-text-gray);
  font-size: 14px;
  font-weight: 500;
}

.text-success {
  color: var(--clearpath-green);
}

.text-xxs {
  font-size: 10px;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xl {
  font-size: 20px;
}

.text-2xl {
  font-size: 24px;
}

.text-xxl {
  font-size: 24px;
}

.text-3xl {
  font-size: 36px;
}

.text-4xl {
  font-size: 48px;
}

.text-48 {
  font-size: 48px;
}

.text-72 {
  font-size: 72px;
}

.text-96 {
  font-size: 96px;
}

.text-right {
  text-align: right;
}

.textContainer {
  flex: 1 1 auto; /* Flexible but can shrink */
  display: flex;
  flex-direction: column;
  min-width: 0; /* Crucial for truncation */
  gap: 5px;
}

.textShadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.text-white {
  color: var(--clearpath-white);
}

.titleContainer {
  font-size: 16px;
  font-weight: 500;
  color: var(--clearpath-white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toggleBtnUnselected {
  all: unset;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--clearpath-black);
  color: var(--clearpath-white);
  font-size: 14px;
  font-weight: 600;
  margin: 0.5rem 0;
}

.toggleBtnSelected {
  all: unset;
  cursor: pointer;
  border: 1px solid var(--clearpath-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--clearpath-black);
  color: var(--clearpath-white);
  font-size: 14px;
  font-weight: 600;
  margin: 0.5rem 0;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  --webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.truncatedDescription {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncatedTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 576px) {
  .truncatedTitle {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}

.todoBadge {
  position: absolute;
  right: 0px;
  top: -5px;
  height: 15px;
  width: 40px;
  border-radius: 8px;
  background-color: var(--clearpath-yellow);
  border: 1px solid var(--clearpath-black);
  color: var(--clearpath-black);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  font-weight: 600;
  z-index: 98;
}

.top-0 {
  top: 0;
}

.top-20 {
  top: 20px;
}

.top-100 {
  top: 100px;
}

.two-column-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.userContainer {
  display: flex;
  gap: 10px;
}

@media screen and (min-width: 992px) {
  .userContainer {
    display: unset;
  }
}

.userContainer:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.03)
  );
}

.userImageContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .userImageContainer {
    padding: 10px;
  }
}

.userImageContainer img {
  width: 100%;
  border-radius: 50%;
  border: 1px solid var(--clearpath-light-gray);
  object-fit: cover;
  aspect-ratio: 1;
}

@media screen and (min-width: 992px) {
  .userImageContainer img {
    width: 50%;
  }
}

.w-auto {
  width: auto;
}

.w-20 {
  width: 20px;
}

.w-40 {
  width: 40px;
}

.w-60 {
  width: 60px;
}

.w-80 {
  width: 80px;
}

.w-100 {
  width: 100px;
}

.w-150 {
  width: 150px;
}

.w-full {
  width: 100%;
}

.white-space-nowrap {
  white-space: nowrap;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}
