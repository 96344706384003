.label {
  position: absolute;
  bottom: -40px;
  font-size: 8px;
  font-weight: 500;
  color: var(--muted-text-gray);
  text-align: center;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  height: 4px;
  background-color: var(--clearpath-yellow);
  position: absolute;
  left: 0;
  border-radius: 2px;
  transition: width 0.3s ease-in-out;
}

.progressBar {
  box-sizing: border-box;
  height: 4px;
  background-color: var(--clearpath-yellow-10);
  position: relative;
  border-radius: 2px;
  margin: 10px;
}

.iconContainer {
  position: relative;
  z-index: 1;
}

.step {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.step::before {
  content: "";
  border-left: 2px solid var(--clearpath-yellow-30);
  height: 100%;
  position: absolute;
  left: 11px;
  top: 21px;
}

.step:last-child::before {
  border-left: none;
}

.stepBar {
  display: flex;
  position: absolute;
  top: 41%;
  left: 20px;
  right: 20px;
  align-items: center;
  background-color: var(--clearpath-dark-gray);
  height: 5px;
  width: calc(100% - 40px);
  z-index: 1;
}
.stepBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
}

.stepBarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  position: relative;
}

@media screen and (min-width: 768px) {
  .stepBarWrapper {
    width: 50%;
  }
}

.stepBarProgress {
  display: flex;
  position: absolute;
  top: 41%;
  left: 20px;
  right: 20px;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  height: 5px;
  width: 0; /* Start with zero width and dynamically adjust */
  z-index: 1;
  transition: width 0.3s ease-in-out; /* Smooth transition */
}

.stepBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
}

.stepContainer {
  margin: 10px 0px 10px 20px;
}

.stepDot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--clearpath-dark-gray);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: 2;
}

.stepDotComplete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(180deg, #fec110 0%, #fec110 0.01%, #cc9800 100%);
  z-index: 2;
}

.stepItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stepNumber {
  font-weight: bold;
  color: var(--clearpath-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepText {
  font-size: 12px;
  padding: 5px;
  color: var(--clearpath-mid-gray);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepTextComplete {
  font-size: 12px;
  padding: 5px;
  color: var(--clearpath-yellow);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
